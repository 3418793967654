import React from "react";
import "../Styles/HomeStyles.scss";
import { Button } from "@mantine/core";
import { Accordion } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/contact-us");
  };
  const FAQ = [
    {
      value: "Will my hire only work for me?",
      description:
        "Absolutely! Our consultant is an extension of your team. They work full-time for only your company during your preferred working hours. They are just like regular team members",
    },
    {
      value: "Do you offer a guarantee?",
      description:
        "Yes! We offer a 90-day guarantee, meaning if you don’t like who you hire and if it doesn't work out for any reason, we’ll find you someone else for the same role for free",
    },
    {
      value: "Where do you hire from?",
      description:
        "We hire from the Philippines and Pakistan. Rest assured, all of our employes are super professional and communicate extremely well",
    },
  ];
  const items = FAQ?.map((item) => (
    <Accordion.Item key={item.value} value={item.value}>
      <Accordion.Control>{item.value}</Accordion.Control>
      <Accordion.Panel>{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));
  return (
    <div className="Home">
      <div className="landing px-sm-0 px-3">
        <div className="header d-flex align-items-center justify-content-center pt-4">
          <h1 className="EB">PP</h1>
        </div>
        <div className="landing_main d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column">
            <h1 className="EB mb-4">
              We find exceptional employees for <br /> you, that cost 90% less
              than <br />
              hiring from the US & UK
            </h1>
            <h3 className="DM mb-4">
              Companies are grappling with a scarcity of competent and <br />{" "}
              reliable talent. We exist to solve this challenge
            </h3>
            <div>
              <Button
                variant="filled"
                size="md"
                color="#d0bfad"
                onClick={handleNavigation}
              >
                Start Hiring
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="features d-flex align-items-center justify-content-center px-sm-0 px-3">
        <div className="d-flex flex-column align-items-center">
          <h1 className="EB mb-3">
            Hire top 1% of international talent for 50-90% <br /> less than US
            and UK equivalents
          </h1>

          <img src="images/underline.svg" alt="" className="mb-5" />
          <div className="d-flex flex-column tick_cont">
            <div className="d-flex align-items-center justify-content-start tick_box mb-5">
              <img src="images/tick.svg" alt="" className="me-3" />
              <h4 className="DM">Experienced with remote work</h4>
            </div>
            <div className="d-flex align-items-center justify-content-start tick_box mb-5">
              <img src="images/tick.svg" alt="" className="me-3" />
              <h4 className="DM">Excellent English</h4>
            </div>
            <div className="d-flex align-items-center justify-content-start tick_box mb-5">
              <img src="images/tick.svg" alt="" className="me-3" />
              <h4 className="DM">Delightful to work with</h4>
            </div>
          </div>
        </div>
        <img src="images/sprinkle.svg" className="sprinkle" alt="" />
      </div>
      <div className="roles  d-flex flex-column align-items-center px-sm-0 px-3">
        <h1 className="EB mt-5">Roles that we currently support</h1>
        <img src="images/underline.svg" alt="" className="mb-5" />
        <div className="user_roles d-flex align-items-center justify-content-center flex-wrap flex-sm-nowrap">
          <div className="user_role mx-4 d-flex flex-column align-items-center justify-content-center mb-sm-0 mb-5">
            <img src="images/developer.png" alt="" />
            <h3 className="DM">Developers</h3>
          </div>
          <div className="user_role mx-4 d-flex flex-column align-items-center justify-content-center mb-sm-0 mb-5">
            <img src="images/data.png" alt="" />
            <h3 className="DM">Data Analysts</h3>
          </div>
          <div className="user_role mx-4 d-flex flex-column align-items-center justify-content-center mb-sm-0 mb-5">
            <img src="images/designer.png" alt="" />
            <h3 className="DM">Designers</h3>
          </div>
        </div>
        <img src="images/Sprinkle2.svg" className="sprinkle_2" alt="" />
      </div>
      <div className="benefits d-flex flex-column align-items-center px-sm-0 px-3">
        <h1 className="EB mb-4">
          Companies are under pressure to adapt and redefine their <br />{" "}
          business models to stay relevant, compete, and win.{" "}
        </h1>
        <div className="d-flex aalign-items-center justify-content-center flex-sm-nowrap flex-wrap">
          <div className="d-flex flex-column align-items-center benefit mx-2 mb-sm-0 mb-5">
            <img src="images/leaderboard.png" alt="" />
            <div className="benefits_box d-flex flex-column align-items-center py-3 px-3  ">
              <h2 className="DM mb-3">Consultants to employes</h2>
              <h4 className="DM ">
                We provide individual employees, managed teams of employees, or
                full-scale teams. We hire diverse individuals, train them, and
                deploy them to you
              </h4>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center benefit mx-2 mb-sm-0 mb-5">
            <img src="images/saving-money.png" alt="" />
            <div className="benefits_box d-flex flex-column align-items-center py-3 px-3  ">
              <h2 className="DM mb-5">Save up to 80%</h2>
              <h4 className="DM ">
                Traditional hiring has become extremely expensive. Our employes
                are considerably more cost-effective
              </h4>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center benefit mx-2 mb-sm-0 mb-5">
            <img src="images/on-time.png" alt="" />
            <div className="benefits_box d-flex flex-column align-items-center   py-3 px-3  ">
              <h2 className="DM mb-5">Hire talent faster</h2>
              <h4 className="DM">
                We’ll find and place a highly qualified consultant within 1 week
              </h4>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Button
            variant="filled"
            size="md"
            color="#d0bfad"
            onClick={handleNavigation}
          >
            Start Hiring
          </Button>
        </div>
      </div>
      <div className="check_list d-flex  flex-column align-items-center justify-content-center">
        <div className="check_list_cont p-sm-5  p-3  mb-5">
          <div className="check_list_top_box p-3 d-flex flex-column align-items-center justify-content-between">
            <h1 className="EB">
              Hire the best of the best for $900 to $3000 per month
            </h1>
            <h5 className="mt-3">
              Hire an amazing full-time employee with over 2 years of experience
              for around $1000 <br /> per month. More premium roles will require
              a higher investment
            </h5>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>End to End Employee Management</span> - we cover
                everything and ensure seamless integration
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>Dashboard</span> - access an interactive dashboard with
                real-time access to employee info including holiday tracking
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>HR Administration</span> - reduce administrative burden
                through our comprehensive services including employee record
                maintenance, time and attendance tracking, leave management,
                benefits, pension, insurance, and more
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>Payroll Processing</span> - we facilitate consultant
                payments, guaranteeing prompt and accurate delivery of wages
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>24/7 IT & HR Support</span> - our IT and HR teams are
                available around the clock, ensuring your employee is fully
                supported
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>Complimentary Laptops</span> - we provide the laptop to
                employees to enhance efficiency and productivity
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>Training and Development</span> - we provide courses,
                books, and resources for employees to up-skill themselves
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>Industry Specific Tool Suite</span> - we provide the whole
                software stack for employees including slack and figma
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>3-day replacement guarantee</span> - if you don’t like who
                you hire and if it doesn't work out for any reason, we’ll find
                you someone else for the same role for free within 3 days
              </h4>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-2 d-flex align-items-center justify-content-center">
              <img src="images/tick.svg" alt="" />
            </div>
            <div className="col-10">
              <h4>
                <span>Cloud model </span> - at any time, if you need more short
                term support on projects, we can provide flexible talent
              </h4>
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="filled"
            size="md"
            color="#d0bfad"
            onClick={handleNavigation}
          >
            Start Hiring
          </Button>
        </div>
      </div>
      <div className="process d-flex flex-column align-items-center pt-5 ">
        <div className="d-flex flex-column align-items-center">
          <h1 className="EB">Our Process</h1>
          <img src="images/underline-3.svg" alt="" />
        </div>
        <div className="row processes my-5">
          <div className="col-1 my-4">
            <h1 className="EB me-5">1</h1>
          </div>
          <div className="col-11 d-flex align-items-center my-4">
            {" "}
            <h3 className="DM">
              Get in touch and let us know about the person you're looking for
            </h3>
          </div>
          <div className="col-1 my-4">
            <h1 className="EB me-5">2</h1>
          </div>
          <div className="col-11 d-flex align-items-center my-4">
            {" "}
            <h3 className="DM">
              We'll share the best profiles that fit your needs
            </h3>
          </div>
          <div className="col-1 my-4">
            <h1 className="EB me-5">3</h1>
          </div>
          <div className="col-11 d-flex align-items-center my-4">
            {" "}
            <h3 className="DM">
              If any catch your interest, you can arrange calls to explore
            </h3>
          </div>
          <div className="col-1 my-4">
            <h1 className="EB me-5">4</h1>
          </div>
          <div className="col-11 d-flex align-items-center my-4">
            {" "}
            <h3 className="DM">
              Confirm if you’d like to hire and engage the selected consultant
            </h3>
          </div>
        </div>
        <img src="images/underline-8.svg" alt="" className="underline" />
      </div>
      <div className="selling_points d-flex flex-column align-items-center px-sm-0 px-3">
        <h3 className="DM">
          We’ve made hiring simple, seamless, and hassle free. We only win if
          you win
        </h3>
        <img src="images/checklist.png" alt="" className="my-5" />
        <h3 className="DM">
          All of our employes undergo an extensive screening process. We
          <br />
          thoroughly vet candidates and conduct background checks on everyone
        </h3>
        <img src="images/team-work.png" alt="" className="my-5" />
        <h3 className="DM">
          Your hire works full-time only for you. Whatever your normal business
          hours are, <br /> employes will match them. They communicate how you
          do, use <br /> the tools you use, attend your meetings, and function
          just like full-time employees
        </h3>
        <img src="images/problem-solving.png" alt="" className="my-5" />
        <h3 className="DM">
          We can help you scale faster by building dedicated teams from scratch
          who deliver <br /> projects end to end with a project manager to
          become your main point of contact
        </h3>
      </div>
      <div className="gurantee d-flex flex-column align-items-center pt-5 px-sm-0 px-3">
        <h1 className="EB">Our Guarantee</h1>

        <div className="d-flex align-items-center justify-content-center my-5">
          <div className="sixty d-flex align-items-start mx-sm-5">
            <img src="images/left.svg" className="quotations" />
            <img src="images/90.png" alt="" className="number" />
            <img src="images/right.svg" className="quotations" />
          </div>
          <img src="images/partner.png" alt="" className="mx-5 hands" />
        </div>
        <h3 className="DM my-5">
          We offer a 90-day guarantee; if you don’t like who you hire and if it{" "}
          <br />
          doesn't work out for any reason, we’ll find you someone else for the
          <br />
          same role for free within 3 days
        </h3>
        <h3 className="DM">
          This also means that you need to sign up for a minimum of 3 months
        </h3>
      </div>
      <div className="faq d-flex flex-column align-items-center ">
        <img src="images/spiral.svg" alt="" className="spiral" />
        <Accordion
          defaultValue="Will my hire only work for me?"
          chevron={<img src="images/chevron.png"></img>}
        >
          {items}
        </Accordion>

        <div className="mt-5">
          <Button
            variant="filled"
            size="md"
            color="#d0bfad"
            onClick={handleNavigation}
          >
            Start Hiring
          </Button>
        </div>
        <img src="images/question-mark.svg" alt="" className="question_mark" />
      </div>
      <div className="footer d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex align-items-center">
          <img src="images/line.png" alt="" className="mx-5" />
          <h1 className="EB">PP</h1>
          <img src="images/line.png" className="mx-5" alt="" />
        </div>
        <p className="copyright">© Copyright 2024 • People Partner</p>
      </div>
    </div>
  );
};

export default Home;
