import React from "react";
import "../Styles/ContactUsStyles.scss";
import { InlineWidget } from "react-calendly";
const ContactUs = () => {
  return (
    <div className="contact_us_main ">
      <InlineWidget
        url="https://calendly.com/contact-9eg/30min"
        styles={{
          height: "700px",
        }}
        pageSettings={{
          primaryColor: "#032123",
          backgroundColor: "#f1f1ef",
        }}
      />
    </div>
  );
};

export default ContactUs;
